<script>
import StoreAPIKeyForm from '@app/components/StoreAPIKeyForm.vue';

export default {
  components: {
    StoreAPIKeyForm,
  },
  head: {
    title: 'APIs développeur - Paramètres',
  },
};
</script>

<template>
  <div>
    <StoreAPIKeyForm />
  </div>
</template>
