<script>
export default {
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    authUser() {
      return this.$store.getters['auth/user'];
    },
  },
  methods: {
    generateKey() {
      this.isLoading = true;
      this.$store.dispatch('auth/generateAPIKey')
        .finally(() => (this.isLoading = false));
    },
    deleteKey() {
      this.isLoading = true;
      this.$buefy.dialog.confirm({
        message: 'Attention ! <br> Cette action est définitive.',
        type: 'is-danger',
        focusOn: 'cancel',
        onCancel: () => (this.isLoading = false),
        onConfirm: () => this.$store.dispatch('auth/deleteAPIKey')
          .finally(() => (this.isLoading = false)),
      });
    },
  },
};
</script>

<template>
  <section class="columns">
    <div class="column is-4 content pr-10 is-small">
      <h2 class="is-size-5">
        APIs Développeur <AppFeatureBadge class="ml-3" position="is-right" />
      </h2>
      <p>
        Vous êtes développeur ou faites appel à un prestataire&nbsp;?
      </p>
      <p>
        <strong>Accédez à vos données à travers une API</strong>
        en générant une clé unique associée à votre compte.
      </p>
    </div>

    <div class="column">
      <div class="box">
        <b-notification type="is-light" :closable="false">
          Vous pouvez utiliser cette clé côté client ou côté serveur.
          Néanmoins, vous n'aurez accès qu'à des APIs en lecture seule.
        </b-notification>

        <go-pro-wrapper>
          <b-field v-if="authUser.public_api_token" label="Votre clé d'API">
            <b-input :value="authUser.public_api_token" readonly expanded />
            <p class="control">
              <b-button
                type="is-primary"
                v-clipboard:copy="authUser.public_api_token"
                v-clipboard:success="() => this.$copySuccess()"
                v-clipboard:error="() => this.$copyError()"
              >
                Copier
              </b-button>
            </p>
          </b-field>

          <b-field class="buttons">
            <b-button
              type="is-primary"
              :loading="isLoading"
              @click="generateKey"
            >
              {{ authUser.public_api_token ? 'Re-générer' : 'Générer' }} ma clé d'API
            </b-button>
            <b-button
              v-if="authUser.public_api_token"
              class="ml-3"
              type="is-text has-text-danger"
              :loading="isLoading"
              @click="deleteKey"
            >
              Supprimer la clé
            </b-button>
          </b-field>
        </go-pro-wrapper>
      </div>
    </div>
  </section>
</template>
